import React, { Component } from 'react';
import CloseIcon from '../../util/icons/components/CloseIcon';
import PropTypes from 'prop-types';

class Notification extends Component {
  constructor (props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose (event) {
    if (event && event.stopPropagation) event.stopPropagation();
    this.props.handleClose(this.props.notification.id);
  }

  render () {
    const className = 'notification ' + this.props.notification.severity;
    const style = {
      zIndex: `${100 - this.props.index}`
    };
    return (
      <div className='notification-wrapper'
        data-cy="notification"
        style={ style }>
        <div
          className={ className }>
          <div className="bb-page-wrapper info-container">
            <span
              onClick={ this.handleClose }
              data-cy="close-notification-icon"
              className='close-icon'>
              <CloseIcon />
            </span>
            <h4 data-cy="notification-title">
              { this.props.notification.title }
            </h4>
            <p data-cy="notification-message">
              { this.props.notification.message }
            </p>
          </div>
        </div>
      </div>
    );
  }
}

Notification.propTypes = {
  notification: PropTypes.object,
  handleClose: PropTypes.func,
  index: PropTypes.number
};

export default Notification;
