/* global localStorage */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  CSSTransition,
  TransitionGroup } from 'react-transition-group';
import { getNotifications } from '../reducer';
import { hideNotification, showNotification } from '../actions';
import Notification from './Notification';
import PropTypes from 'prop-types';
import './notifications.scss';

const mapDispatchToProps = dispatch => ({
  hideNotification: id => dispatch(hideNotification(id)),
  showNotification: (title, message, severity) =>
    dispatch(showNotification(title, message, severity))
});

const mapStateToProps = state => ({
  notifications: getNotifications(state)
});

class Notifications extends Component {
  componentDidMount () {
    const now = new Date().valueOf();
    const reloaded = localStorage.getItem('goodbeef-index-reload');
    if (reloaded && (now - reloaded) < (20 * 1000)) {
      this.props.showNotification(
        'Reloaded',
        'The page was out of date and needed to be reloaded'
      );
    }
  }
  render () {
    return (
      <TransitionGroup id='notifications'
        className={ 'base' }>
        {this.props.notifications.map((notification, index) => (
          <CSSTransition
            key={notification.id}
            timeout={500}
            classNames='notification-animate' >
            <Notification
              index={ index }
              notification= {notification}
              handleClose={ this.props.hideNotification } />
          </CSSTransition>
        ))}
      </TransitionGroup>
    );
  }
}

Notifications.propTypes = {
  hideNotification: PropTypes.func,
  showNotification: PropTypes.func,
  notifications: PropTypes.array
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
